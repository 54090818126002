<template>
    <div>
        <el-table :data="rs">
            <el-table-column prop="code" label="编码" width="180px"></el-table-column>
            <el-table-column prop="name" label="名称" width="180px"></el-table-column>
            <el-table-column prop="val" label="值" width="180px"></el-table-column>
            <el-table-column prop="remark" label="说明"></el-table-column>
        </el-table>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    export default {
        name: "account",
        extends: ax.control(),
        data() {
            return {};
        },
        mounted() {
            this.loaddata();
        },
        methods: {
            test() {
                ax.base
                    .post("/test/queryUserInfo", this.login)
                    .then(function(rs) {
                        console.log(rs);
                    })
                    .catch(function(err) {
                        alert(JSON.stringify(err.response.data));
                    });

            },
            loaddata() {
                let _this = this;
                ax.base
                    .post("/syc/getType", { type: "" })
                    .then((data) => {
                        _this.rs = data.data;
                        console.log(data);
                    })
                    .catch((data) => {
                        console.log(data);
                    });
            },
        },
    };
</script>